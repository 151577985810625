.who-container {
  cursor: grab;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  position: relative;
  background-color: #000000;
  @media(max-width: 450px){
    overflow: hidden;
  }
}
video {
  position: absolute;
  top: 0;
  // left: 37.5%;
  // right: 61%;
  display: block;
  width: 99.8vw;
  min-height: 100%;
  // transform: translate(-50%,0%);
  @media(max-width: 768px) and (orientation: portrait){
  }
  @media(max-width:450px){
    width: 690%;
    position: relative;
    left: 50%;
    right: 0;
    transform: translate(-50%,0%);
  }
}
.who-sub-container {
  width: 100%;
  z-index: 3;
  position: relative;
  top: 5%;
  @media(max-width: 450px){
    position: absolute;
    top: 35%;
  }
  @media(max-width: 375px){
    position: absolute;
    top: 31%;
  }
}
.content-container {
  width: 100%;
}
 .who-open-content, .who-content, .who-content-think {
  border-top: 2px dashed grey;
  border-bottom: 2px dashed gray;
}
.content-container h1:hover {
  opacity: 0.6;
  cursor: pointer;
}
.who-title  {
  width: 100%;
  position: absolute;
  top: 160%;
  h7{
    position: relative;
    top: 0px;
    color: white;
    font-weight: 350;
    @media(max-width: 450px){
    top: -18px;
    font-size: 1.5rem;
    color: white;
    font-weight: 600;
    }
  }
  hr{
    position: relative;
    left: 45.3%;
    width: 175px;
    margin-top: 7px;
    margin-bottom:7px ;
    border: none;
    background-color: #444d4f;
    height: 4px;
    opacity: 0.5;
    @media(max-height:850px){
      left: 44.5%;
    }
    @media(max-width:768px) and (orientation: portrait){
    left: 38.3%;
    }
    @media(max-width:450px){
      left: 28%;
      top: -19px;
    }
    @media(max-width:375px){
      left: 26%;
    }
  }
  @media(max-height:850px){
    top: 120%;
  }
  @media(max-width:450px){
  }
}
.who-content {
  color: #fff;
  font-weight: 200;
  font-size: 4rem;
  padding: 0;
  transition: 0.4s; 
  align-content: center;
  justify-content: center;
  border-top: none;
  @media(max-width:450px){
    font-size: 3rem;
    width: 100%;

  }
}
.who-content:hover {
  padding: .5rem;
  background-color: #0d1212;

}
.who-content-think {
  color: #fff;
  font-weight: 200;
  font-size: 4rem;
  padding: 0;
  transition: 0.4s; 
  align-content: center;
  justify-content: center;
  @media(max-width:450px){
    font-size: 3rem;
    width: 100%;

  }
}
.who-content-think:hover {
  padding: .5rem;
  background-color: #0d1212;
}

.who-open-content, .who-open-think {
  position: relative;
  padding: 1rem;
  padding-bottom: 50px;
  height: 9rem;
  transition: 0.4s;
  background-color: #000000;
  opacity: 0.9;
  z-index: 3;
  border-top: none;
  @media(max-width: 768px) and (orientation: portrait) {
    height: 12rem;
 }
  @media(max-height:850px){
    height: 9rem;
  }
  @media(max-width:450px){
    height: 15rem;
  }
  @media(max-width:375px){
    height: 14.5rem;
  }
  h1{
  font-size: 1.5rem;
  font-weight: 400;
  width: 73%;
  margin-left: 200px;
  margin-top: 13px;
  @media(max-height:800px){
    font-size: 1.25rem;
    width: 77%;
    margin-left: 152px;
    margin-top: 21px;
  }
  @media(max-width:768px) and (orientation: portrait){
    width: 100%;
    margin-left: 0;
    margin-top: -5px;
  }
  @media(max-width:450px){
    font-size: 1.25rem;
    margin-left: 0px;
    margin-top: 0;
  }
}
}
.who-open-think{
  border-top: 2px dashed grey;
  border-bottom: 2px dashed grey;
}
.cross{
    position: absolute;
    bottom: -10px;
    right: 330px;
    width: 70px;
    height: 55px;
    background: url(../../assets/logos/crossMobile.png);
    background-repeat: no-repeat;
    @media(max-height:850px){
      background: url(../../assets/logos/crossMobile.png);
      background-repeat: no-repeat;
      bottom: 0px;
      right: 220px;
      width: 70px;
      height: 45px;
    }
    @media(max-width:450px){
      display: none;
    }
  }
.who-open-content:hover {
  transition: 1s;
}
#overlay {
  position: absolute; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100vw; /* Full width (cover the whole page) */
  height: 100vh; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(28, 41, 44, 0.6); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  opacity: 1;
  transition: opacity 0.3s;
}

