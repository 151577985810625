.our-work-container {
  cursor: grab;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/background-images/OurWorkBackground.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.our-work-content{
  @media(max-width: 450px){
    height: 100%;
    display: flex;
    overflow-y: auto;
  }
}
#square-container{
  display: flex;
  flex-wrap: wrap;
  width: 85vw;
  align-content: center;
  justify-content: center;
  height: 70vh;
  padding: 0 10px;
  @media(max-width: 1399px){
    overflow-y:scroll;
  }
  @media(max-width: 450px){
    height: 297vh;
    width: 80vw;
    padding: 36px 10px;
  }
}
.square {
  margin: 0;
  border-collapse: collapse;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  transition: 0.25s ease;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media(max-width:500px){
    width: 85%;
    height: 15vh;
    border-left: 0;
    border-right: 0;
    &:first-child{
      border-top: 0;
    }
    &:last-child{
      border-bottom: 0;
    }
  }
  
  @media(max-width:799px) and (min-width: 501px){
    width: 50%;
    height: 15vh;
    &:nth-child(1n-2){
      border-left: 0;
    }
    &:nth-child(1n-1){
      border-top: 0;
    }
    &:nth-child(2n+2){
      border-right: 0;
    }
    &:nth-child(1n+19){
      border-bottom: 0;
    }
  }
  @media(min-width:800px) and (max-width: 1399px){
    width: 33%;
    height: 17vh;
    &:nth-child(1n-3){
      border-left: 0;
    }
    &:nth-child(1n-1){
      border-top: 0;
    }
    &:nth-child(3n+3),
    &:nth-child(20){
      border-right: 0;
    }
    &:nth-child(1n+19){
      border-bottom: 0;
    }
  }
  @media(min-width:1400px){
    width: 20%;
    height: 17vh;
    &:nth-child(1n-3){
      border-left: 0;
    }
    &:nth-child(16){
      border-left: 1px solid white;
    }
    &:nth-child(1n-1){
      border-top: 0;
    }
    &:nth-child(5n+5){
      border-right: 0;
    }
    &:nth-child(1n+16){
      border-bottom: 0;
    }
  }
  
}
.square{
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &:hover{
    img{
      opacity: 1;
      left: 0;
    }
    h10{
      opacity: 0;
    }
  }
  img{
    min-height: 100%;
    min-width: 100%;
    top: 0;
    right: 0;
    left: -100%;
    bottom: 0;
    opacity: 0;
    transition: ease-in 0.3s;
  }
}
h10{
  position: absolute;
  font-size: medium;
  color: white;
  @media(max-width: 400px){
    font-size: small;
  }
}
.work-title  {
  width: 100%;
  position: absolute;
  top: 85%;
  hr{
    display: inline-block;
    width: 150px;
    border: none;
    background-color: #252525;
    height: 4px;
    opacity: 0.8;
  }
  @media(max-height:850px){
    top: 84%;
    font-size: medium;
  }
  @media(max-width: 450px){
    display: none;
  }
}

