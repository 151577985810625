.what-container{
  cursor: grab;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
  background-color: #000000;
  background-image: url('../../assets/background-images/WhatWeDoBackground.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.what-content-container{
  width: 40%;
  float: left;
  text-align: center;
  position: relative;
  @media(max-width: 1024px) and (orientation: landscape){
    right: 25%;
 }
  @media(max-height: 850px){
    right: 7%;
  }
  @media(max-width:768px) and (orientation: portrait){
    left: 0%;
    width: 100%;
  }
  @media(max-width: 450px){
    right: -4%;
    width: 95%;
  }
  
}

.what-title-container{
  position: relative;
  width: 80%;
  text-align: start;
  margin: auto;
  top:0;
  transition:top 1s;
  &.hidden{
   top:100%;
  }
  @media(max-width: 450px){
    width: 80%;
  }
}


.what-title {
  font-size: 5.5rem;
  color: white;
  font-weight: 200;
  @media(max-height: 850px){
    font-size: 4.5rem;
  }
  @media(max-width: 450px){
    font-size: 3rem;
    position: relative;
    left: 4%;
  }
  @media(max-width: 375px){
    font-size: 2rem;
    position: relative;
    top: 20px;
  }
}

.what-content {
  font-size: 1.5rem;
  color: gray;
  font-weight: 400;
  @media(max-width: 450px){
    margin-right: -30px;
  }
  @media(max-width: 375px){
    margin-top: 20px;
  }
}

.what-logo-container {
  border: 3px solid #137398;
  border-radius: 50%;
  text-align: center;
  position: relative;
  left: -1%;
  width: 13rem;
  height: 13rem;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(29, 28, 42);
  -webkit-box-shadow: inset 0px 0px 60px -8px rgba(48,187,203,1);
  -moz-box-shadow: inset 0px 0px 60px -8px rgba(48,187,203,1);
  box-shadow: inset 0px 0px 60px -8px #1b4d5e;
  @media(max-width:768px) and (orientation: portrait) {
    right:11% ;
  }
  @media(max-width:450px){
    width: 9rem;
    height: 9rem;
    right: 10%;
  }
  @media(max-width:375px){
    width: 8rem;
    height: 8rem;
    position: absolute;
  }
  .line-container{
    position: absolute;
    top:-105vh;
    left: calc(50% - 20px);
    width: 40px;
    height: 98.5vh;
    transition: all 0.5s;
    &.hide{
      height: 5vh;
      top: -5vh;
    }
    @media(max-height:850px){
      top: -105vh;
      height: 96.75vh;
    }
    @media(max-width:768px) and (orientation: portrait) {
      top: -70vh;
      height: 64vh;
    }
    @media(max-width:450px){
      top:-60vh;
      height: 51.5vh;
    }
    @media(max-width:375px){
      top:-55vh;
      height: 45.55vh 
    }
    }
    span:first-child{
      position: relative;
      right: 75%;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 3px solid #137398;
      background-color: rgb(29, 28, 42);
      -webkit-box-shadow: inset 0px 0px 45px -8px rgba(48,187,203,1);
      -moz-box-shadow: inset 0px 0px 45px -8px rgba(48,187,203,1);
      box-shadow: inset 0px 0px 45px -8px #1b4d5e;
      display:block;
    }
    span:last-child{
      position:relative;
      height: calc(100% - 40px);
      width: 3px;
      background-color: #137398;
      left:50%;
      display: block;
    }
   }
.what-logo-container2 {
  border: 3px solid #137398;
  border-radius: 50%;
  text-align: center;
  position: relative;
  left: 66%;
  width: 13rem;
  height: 13rem;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(29, 28, 42);
  -webkit-box-shadow: inset 0px 0px 60px -8px rgba(48,187,203,1);
  -moz-box-shadow: inset 0px 0px 60px -8px rgba(48,187,203,1);
  box-shadow: inset 0px 0px 60px -8px #1b4d5e;
  @media(max-width: 1024px) and (orientation: landscape) {
    left: 50% !important;
  }
 @media(max-height:850px){
    left:60%;
  }
  @media(max-width:768px) and (orientation: portrait) {
    left: 30% !important ;
  } 
   @media(max-width:450px){
    width: 9rem;
    height: 9rem;
    left: 15% !important;
  }
  @media(max-width:375px){
    left: 57% !important;
    width: 8rem;
    height: 8rem;
  }

 
  .line-container2{
    position: absolute;
    top:-105vh;
    left: calc(50% - 20px);
    width: 40px;
    height: 98.5vh;
    transition: all 0.5s;
    &.hide{
      height: 5vh;
      top: -5vh;
    }
    @media(max-height:850px){
      top: -105vh;
      height: 96.75vh;
    }
    @media(max-width:768px) and (orientation: portrait) {
      top: -70vh;
      height: 64vh;
    }
    @media(max-width:450px){
      top:-60vh;
      height: 51.5vh;
    }
    @media(max-width:375px){
      top:-55vh;
      height: 45.55vh ;
    }
    
    span:first-child{
      position: relative;
      right: 75%;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 3px solid #137398;
      background-color: rgb(29, 28, 42);
      -webkit-box-shadow: inset 0px 0px 45px -8px rgba(48,187,203,1);
      -moz-box-shadow: inset 0px 0px 45px -8px rgba(48,187,203,1);
      box-shadow: inset 0px 0px 45px -8px #1b4d5e;
      display:block;
    }
    span:last-child{
      position:relative;
      height: calc(100% - 40px);
      width: 3px;
      background-color: #137398;
      left:50%;
      display: block;
    }
   }
}

.what-logo {
  width: 110px;
  height: 110px;
  opacity: 0;
  position: relative;
  @media(max-height: 850px){
    right: 0%;
  }
  @media(max-width: 450px){
    width: 100px;
    height: 100px;
  } 
  @media(max-width: 375px){
    width: 80px;
    height: 80px;
  }
  
}
.what-logo-container:hover h2 {
  animation: textRotate 1s;
  animation-fill-mode: forwards;
  cursor: pointer;
    
}
.what-logo-container2:hover h4 {
  animation: textRotate 1s;
  animation-fill-mode: forwards;
  cursor: pointer;
}
.what-logo-container:hover .what-logo {
  animation: logoRotate 1s;
  opacity: 1;
  cursor: pointer;
}

.what-logo-container2:hover .what-logo {
  animation: logoRotate 1s;
  opacity: 1;
  cursor: pointer;
}
@keyframes textRotate {
  from {transform: rotate(0deg);}
  0% {
    opacity:1;
  }
  /* 50% { transform: rotate(180deg);} */
  to {transform: rotate(360deg);}
  100% {
    opacity:0;
  }
}
@keyframes logoRotate {
  from {transform: rotate(0deg);}
  0% {
    opacity:0;
  }
  /* 50% { transform: rotate(180deg);} */
  to {transform: rotate(360deg);}
  100% {
    opacity:1;
  }
}

.services-content-container {
  position:absolute;
  top: -100%;
  width: 57vw;
  left: 18vw;
  transition: top 1s;
  &.show{
    top:0;
    .vl{
      display: none;
    }
  }
  @media(max-width:450px){
    top: -140%;
  }
}

.services-content{
  margin-top: 30vh;
  text-align: start;
  &.hide{
    display: none;
  }
  @media(max-height:850px){
    text-align: start;
    position: relative;
    left: 5%;
    margin-left: -1vw;
    margin-top: 30vh;
  }
  @media(max-width:768px) and (orientation: portrait){
    margin-left: -9vw;
    width: 144%;
    text-align: center;
    margin-top: 10vh; 
  }
  @media(max-width:450px){
    margin-top: 0;
    width: 100%;
    margin-left: 35px;
    text-align: center;
  }
  @media(max-width:375px){
    margin-left: 0;
    margin-top: 5vh;
  }
}

.services-desc{
  color: gray;
  font-size: 1.5rem;
  font-weight: 600;
  @media(max-height:850px){
    font-size: 1.25rem;
  }
  @media(max-width: 450px){
    font-size: 1.5rem;
    text-align: center;
    width: 400px;
    position: relative;
    right: 48%;
    top: -15px;
  }
  @media(max-width:375px){
    margin-left: 10px;
    width: 300px;
    font-size: 1rem;
    right: 20%;
  }
}

.services-title{
  font-size: 5rem;
  font-weight: 300;
  padding: 2rem 0;
  // margin-left: 350px;
  @media(max-height:800px){
    font-size: 3rem;
  }
  @media(max-width:450px){
    font-size: 2rem;
    padding: 1rem;
    position: relative;
    right: 10%;
  }
  @media(max-width: 375px){
    font-size: 2.5rem;
    position: relative;
    right: 10%;
    width: 130%;
  }
}

.services-link{
  color: #30bbcb;
  font-weight: 500;
  font-size: 1.5rem;
  padding: 1rem 0rem;
  width: 18%;
  @media(max-width:768px) and (orientation: portrait){
    width: 96%; 
  }
  @media(max-width:450px){
    width: 80%;
    margin-left: -10px;
  }
  @media(max-width: 375px){
    width: 100%;
    padding: 0;
    margin-left: 10px;
    font-size: 1.5rem;
    
  }
}

.services-link:hover{
  cursor: pointer;
  color: #fff;
}

.services-logo{
  // border: 3px solid #137398;
  position: relative;
  top: 35%;
  background:  -webkit-linear-gradient(left, #17ddd7 0%, #0da3e5 50%, #0467f5 100%,);
  background-clip: text;
  -webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
  font-family: 'roboto';
  font-size: 1.5rem;
  cursor: pointer;
  @media(max-width:450px){
    font-size: 1.5rem;
    position: relative;
    left: -35% !important;
  }
  @media(max-width:375px){
    font-size: 1.5rem;
    position: relative;
    left: -35% !important;
  }

  .vl{
    position:absolute;
    top: -11vh;
    left:50%;
    width: 3px;
    height: 11vh;
    background:#137398;
    border:none;
    @media(max-width:375px){
      height: 10.5vh;
    }
  }
  @media(max-width:768px) and (orientation: portrait){
    right: 5% ;
  }
  @media(max-width:450px){
    width: 10rem;
    height: 10rem;
    left: 40%;

  }
  
}


.services-logo > h1,h2,h4{
 background:  -webkit-linear-gradient(left, #17ddd7 0%, #0da3e5 50%, #0467f5 100%,);
 background-clip: text;
 font-size: 1.5rem;
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent; 
 z-index: 10;
 cursor: pointer;
 @media(max-height:850px){
  left: 25%;
}
 @media(max-width:450px){
  font-size: 1.25rem;
}
}
h1{
  z-index: 0 !important;
}
h2{
  position: absolute;
  left: 8%;
  justify-content: center;
  @media(max-width:450px){
    left: 0%;
    width: 100%;
  }
  @media(max-width:375px){
    width: 100%;
  }
}
h3,h6{
  position: relative;
  top: -20px;
  font-size: 1.5rem;
  color: gray;
  font-weight: 600;
}
h3{
  top: 0px !important;
}
h6{
  top: -10px;
  color: white;
}
h4{
  position: absolute;
  left: 20%;
  @media(max-width:450px){
    left: 12%;
  }
}

.pdf-open{
  align-items: center;
  position: absolute;
  top: 0%;
  left: 0vw;
  width: 60vw;
  height: 95vh;
  @media(max-height:800px){
    top: -64%;
  left: 0vw;
  width: 60vw;
  height: 92vh;
  }
  @media(max-width:450px){
    left: -105px;
    width: 92vw;
    height: 125vh;
  }
  @media(max-width:375px){
    left: -13vw;
    width: 90vw;
    height: 100vh;
  }
    iframe{
      display: block;
      width: 100%;
      height: 100%;
    }
  
  #cross{
    position: fixed;
    top: 45%;
    left: 72%;
    width: 50px;
    height: 50px;
    background: url(../../assets/logos/crossMobile.png);
    background-repeat: no-repeat;
    @media(max-width:450px){
      top: 5%;
      left: 53%;
      background: url(../../assets/logos/crossPDF.png);
    }
  }

}
.pdf-close{
  display: none;
}
.arrow{
  content: "\2192";
  position: relative;
  left: 68%;
  bottom: 49px;
  font-size: 48px;
  font-weight: 100;
  @media(max-height:850px){
    left:92%;
  }
  @media(max-width:768px) and (orientation: portrait){
    left: 16%;
  }
  @media(max-width: 450px){
    left: 50%;
    bottom: 40px;
  }
  @media(max-width: 375px){
    left: 50%;
    bottom: 40px;
  }
}
