* {
  box-sizing: border-box;
  margin: 0%;
  padding: 0;
}
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.container,
.swipe-views,
.react-swipeable-view-container,
.swipe-con,
.main-loading-container,
.landing-loading-container {
  height: 100%;
  background: #0d0f10;
}
.main-title {
  border: 2px solid red;
  border-radius: 2rem;
  width: 50%;
  margin: auto;
  padding: 1rem;
}
.slider-container,
.our-work-slider,
.contact-slider,
.our-team-slider,
Slider,
.slick-slider,
.slick-list,
.slick-track,
.landing-slider,
.who-slider,
.what-slider {
  height: 100%;
}
.slick-slide > div {
  height: 100%;
}
.slider-container {
  border: 1px solid black;
}
