*:focus{
  outline:none;
}
.App {
  text-align: center;
  /* align-items: center; */
}

html, body, .App, #root {
  height: 100%;
  
}