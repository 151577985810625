.our-team-container {
  cursor: grab;
  background-color: #000000;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/background-images/ourTeamHexBackground.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.our-team-page-title {
  color: white;
}

h1,
h3 {
  color: white;
}

.hexagon {
  width: 10rem;
  margin: 0.25rem;
  // position: absolute;
}

.hexagon:hover {
  transition: 0.5s linear;
  width: 15rem;
  animation: rotate 1s ;
  cursor: pointer;
}

.nick {
  top: 17%;
  left: 43%;
}

.katey {
  top: 3%;
  left: 52%;
}

.matthew {
  top: 31%;
  left: 52%;
}

.justin {
  top: 59%;
  left: 52%;
}

.josh {
  top: 45%;
  left: 43%;
}

.fabricio {
  top: 45%;
  left: 56.1%;
}

.allison {
  top: 31%;
  left: 47.1%;
}

.jackson {
  top: 73.8%;
  left: 47.9%;
}

/* .hexagon:hover {
  width: 11rem;
} */

@keyframes rotate {
  0% {transform: rotate(0deg);
  width: 10rem;}
  100% {transform: rotate(360deg);}
}
