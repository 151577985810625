.contact-container {
  cursor: grab;
  height: 100%;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/background-images/ContactUsBackground.png');
  background-position: center center;
  background-repeat: none;
  background-size: cover;
  @media(max-width: 450px){
    background-image:url('../../assets/background-images/ContactUsMobile.png');
  }
}
.contact-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 5vh;
  @media(max-height:800px){
  position: relative;
    bottom: 7vh;
  }
  @media(max-width: 450px){
    flex-direction: column;
  }
}
.contact-title {
  padding: 4rem;
  font-weight: 300;
  font-size: 5rem;
  color: #fff;
  position: relative;
  bottom: 7vh;
  @media(max-height:800px){
    font-size: 4rem;
    position: relative;
    bottom: 7vh;;
  }
  @media(max-width: 450px){
    font-size: 3rem;
    width: 120%;
    position: relative;
    right: 10%;
  }
  @media(max-width: 375px){
    position: relative;
    font-size: 2.5rem;
    right: 10%;
    width: 125%;
  }
}
.email-container,
.cell-container {
  padding: 1rem;
  width: 21rem;
  @media(max-width: 450px){
    width: 100%;
    padding:0;
    position: relative;
    left: 0px;
  }
  @media(max-width: 375px){
    position: relative;
  }
}
.email-container{
  margin-left: 100px;
  @media(max-width: 450px){
    margin-left: 0px;
  }
}
.text{
  color: white;
  font-weight: 100;
}
.email,
.cell-number {
  color: #17ddd5;
  font-weight: 400;
}
.email{
  @media(max-width: 450px){
    position: relative;
    left: 0px;
  }
}
.vl{
  border-left: 1px solid #8d8f90;
  height: 60px;
  position: relative;
  left: 5%;
  @media(max-width: 450px){
    position: relative;
    left: 0;
  }
}
