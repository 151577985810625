.menu-title-container {
  cursor: grab;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #2c2c2c;
  padding: 8px;
  @media(max-width:400px){
    padding:10px;
  }
}
.menu-title {
  color: white;
  font-size: 1rem;
  font-weight: 600;
}
.menu-title:hover {
  cursor: pointer;
  color: gray;
}
.menu-open-container {
  position: fixed;
  width: 100%;
  bottom: 3.75%;
  top: 100vh;
  transition: top 0.5s;
  @media(max-height:850px){
    bottom:5%;
  }
  &.open{
    top:0;
  }
}
.menu-open-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height:100vh;
  width: 100vw;
  background-image: url(../../assets/background-images/Menu.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.index-container {
  display: flex;
  position: fixed;
  width: 100%;
  bottom: 36px;
  justify-content: center;
  /* align-items: baseline; */
  height: 0.5rem;
  background-color: #000000;
  box-shadow: 0px 0px 50px #959595;
  
  @media(max-width:450px){
    bottom: 35px;
  }
}
.index-container-open {
  display: flex;
  justify-content: center;
  width: 25rem;
  margin: auto;
  height: 4rem;
  position: relative;
  left: 100%;
  @media(max-width: 768px){
    margin-left: -50px ;
 }
}
.index-box-open {
  padding: 0rem 1rem;
  width: 5rem;
  height: 4rem;
  color: white;
  border-left: 2px solid #959595 ;
  border-right: 2px solid #959595;
  transition: background 0.2s;
  background-color: #2c2c2c;
  &.active{
    background-color:white;
  }
  @media(max-width: 450px){
    display: none;
  }
}
.index-box {
  padding: 0rem 1rem;
  width: 4rem;
  height: 0.5rem;
  color: #d8d8d8;
  border-top: 1px solid black;
  border-left: 1px solid #888888;
  border-right: 1px solid #888888;
  border-bottom: 4.5px solid black;
  transition: background 0.2s;
  z-index: 10;
  background-color: #000000;
  &.active{
    background-color:white;
  }
}
.menu-title-container-open {
  position: fixed;
  bottom: -10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2c2c2c;
}
.menu-title-container-open-title > h1 {
  font-size: 2rem;
  font-weight: 500;
  font-family: 'roboto';
  position: relative;
  left: 5%;
  @media(max-width: 768px){
    left: 25%;
 }
  @media(max-width: 450px){
    left: 102%;
    font-size: 2rem;
  }
}
.menu-title-container-open-title:hover {
  cursor: pointer;
  opacity: 0.6;
}
.menu-title-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  font-size: 1.5rem;
  margin: 1rem;
  color: #fff;
}
.icon:hover {
  color: gray;
  cursor: pointer;
}
.open {
  width: 33%
}
figure{ 
  box-shadow: none !important; 
  -webkit-box-reflect: unset !important; 
}
.menu-slider-container{
  position: fixed;
  left: 21.5vw;
  top: 100vh;
  display: flex;
  border:none;
  transition: top 0.9s;
  @media(max-width:768px) and (orientation: portrait) {
    flex-direction: column;
    left: 38%;
  }
  @media(max-width: 450px){
    flex-direction: column;
    left: 36%;
  }
  @media(max-width: 380px){
    flex-direction: column;
    left: 40%;
  }
}
.menu-open-container.open{
  .menu-slider-container{
    top: 25vh;
    @media(max-width: 768px){
      top: 13vh;
    }
    @media(max-width: 450px){
      top: 2%;
    }
    @media(max-width: 375px){
      top: 2vh;
    }
  }
}
.hex {
  position: relative;
  width: 12vw; 
  height: 25vh;
  margin: 7.5vw 5vw 5.5vw;
  top: 0;
  transition: top 0.3s;

  &:hover{
    top:-10vh;
    img.color{
      opacity: 1;
    }
    @media(max-width: 768px){
      top: -1vh;
    }
  }
  @media(max-width:768px) and (orientation: portrait) {
    width: 25vw;
    height: 30vh;
    margin: 0;
  }
  @media(max-width: 450px){
    width: 27vw;
    height: 28vh;
    margin:0;
    &.active{
      top:-2vh;
    }
  }
  @media(max-width: 375px){
    width: 25vw;
    height: 26vh;
    margin:0;
    &.active{
      top:-2vh;
    }
  }
  &:first-child{
    margin-left: 0;
  }
  &:last-child{
    margin-right: 0;
  }
  &:hover{
    cursor: pointer;
    img.color{
      opacity: 1;
    }
  }
  img{
    position:absolute;
    width: 100%;
    left: 0;
    top: -65px;
    z-index: 9;
    transition: opacity 0.3s;
    &.color{
      opacity: 0;
      z-index: 11;
    }
    @media(max-width:768px) and (orientation:portrait){
      top: -40px;
    }
    @media(max-width: 450px){
      top: 0;
      left: -5px;
    }
  }    
  strong,h4{
    position: absolute;
    width: 85%;
    right: 5%;
    line-height: 50%;
    font-weight: 400;
    font-size: 1.5rem;
    bottom: 0;
    @media(max-width: 1024px) and (orientation: landscape) {
      width: 300%;
      left: -100%;
   }
   @media(max-height: 850px){
    right: 10%;
  }
    @media(max-width: 768px){
      width: 195%;
      right: -50%;
      height: 37%;
    } 
    @media(max-width: 450px){
      right: -20px;
      width: 135%;
    }
  }
  strong{
    @media(max-width: 350px){
      font-size: 1rem;
      right: 16%;
    }
    color: white;
    @media(max-width: 450px){
      right: -20px;
      width: 155px;
      height: 29%;
    }
  }
  h4{
    background:white;
    background-clip: text;
    text-align: center;
    height: 40%;
    width: 120%;
    left: -8%;
    line-height: 100%;
    font-size: 1.25rem;
    @media(max-width: 450px){
      display: none;
    }
  }
}
b{
  color: white;
  font-size: 4rem;
  font-family: 'roboto' !important;
  line-height: 120px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  margin: auto;
  display: block;
  @media(max-height: 800px){
    line-height: 4rem;
    font-size: 3rem;
  }
  @media(max-width: 768px){
    font-size: 4rem;
    line-height: 8rem;
  }
  @media(max-width: 450px){
    font-size: 2.5rem;
    line-height: 8rem;
    right: 2vw;
  }
  @media(max-width: 375px){
    font-size: 2.5rem;
    line-height: 7rem;
    right: 4vw;
  }
}
#menu-title{
  background: #f8f8f8;
  background-clip: text;
  -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
  font-family:'roboto' !important;
  font-size: 1.35rem;
  font-weight: 400;
  position: absolute;
  left: 2%;
  top: 17%;
  @media(max-width: 450px){
    font-size: 1.25rem;
  }
}

