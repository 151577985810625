.page-not-found-container {
	height: 100%;
	background-image: url('../../assets/background-images/ServicesBackground.png');
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
}

.page-not-title {
	font-size: 4rem;	
}
