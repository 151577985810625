.landing-container {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #000000;
	background-image: url('../../assets/background-images/OurTeamBackground.png');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	cursor:grab;
}
.landing-title > h7 {
	color: grey;
	padding: 2rem 0;
	font-size: 1.5rem;
	position: relative;
	@media(max-height:850px){
		margin-bottom: 50px;
	}
	@media(max-width: 450px){
		position: relative;
		top: -60px;
		font-size: 1.5rem;
			color: gray;
			font-weight: 600;
	}
	@media(max-width: 375px){
		top: -60px !important;
	}
}
@keyframes slidein {
	from { transform: scaleX(0); }
	to   { transform: scaleX(1); }
  } 

  .shadow {
	  shape-rendering: geometricPrecision;
	 

  }
  .circle-shadow{
	  border-radius: 50%;
	  position: absolute;
	  width: 325px;
	  height: 325px;
	  left: 0;
	  right: 10px;
	  top: 5px;
	  bottom: 0;
	  margin:auto;
	  box-shadow:
		0px 0px 15px 50px rgba($color: #000000, $alpha: 0.15) ,
		0px 0px 15px 120px rgba($color: #101010, $alpha: 0.4),
		0px 0px 15px 175px rgba($color: #141414, $alpha: 0.2),

  }
	.circle-container{
		position: relative;
		
		
		@media(max-width:450px){
			right: -1%;
		}
		svg { //animation for color around circle
			
			transform: rotate(-90deg);
			// transform: translate(-90deg);
			stroke-dasharray: 1300px;
			// border: 1px solid white;
			stroke-dashoffset: 1300;
			animation: offsettozero 4.5s linear forwards;
		}
	}
  .box {
	margin:auto;
	position: absolute;
	top:0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #252525;
	border: 0.25rem solid #252525;
	height: 12rem;
	width: 10rem;
	outline: 0;
	z-index: 10;
	overflow: hidden; /* Set to none to see fill square outside box */
  }
  .box::before {
	content: url(../../assets/logos/swamplogo.png);
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  .box::after {
	content: "";
	position: absolute;
	bottom: 0%; /* Center sauare */
	left: -50%; /* Center sauare */
	height: 220%;
	width: 220%;
	background-color: #252525;
	border-radius: 30%; /* Smooth edges to appear like liquid */
	animation: spin 7.5s ease-in-out forwards;
	@media(max-width: 450px){
		animation: spin 7.3s ease-in-out forwards;
	}
  }
  .cursor{
	background:url(../../assets/logos/Cursor.png);
	background-repeat: no-repeat;
	width: 100px;
	height: 50px;
	position:absolute;
	bottom:14%;
	display:flex;
	animation:linear infinite alternate;
	animation-name: run;
	animation-duration: 10s;
	@media(max-width:450px){
		animation-duration: 8s;
		bottom: 16%;
		animation-name:run-mobile;
	}
	@media(max-width:375px){
		animation-duration: 8s;
		top: 78%;
		animation-name:run-mobile;
	}
	}
@keyframes run-mobile {
	0% { left: 3%;}
	50%{ left : 17%;}
	100%{ left: 3%;}  
}
@keyframes run { //positioning for cursor 
	0% { left: 10.5%;}
	50%{ left : 13.25%;}
	100%{ left: 10.5%;}
}
@keyframes spin {
	0% {
		transform: translateY(0) rotate(0deg);
	}
	100% {
		transform: translateY(-100%) rotate(600deg);
	}
}
@keyframes offsettozero {
	0%{
		stroke-dashoffset: 1300;
	}
	100% {
	  stroke-dashoffset: 0;
	}
}
.shadow circle{
	@media(max-width: 450px){
		r:150 !important;
	}
}
h3{
	@media(max-width: 450px){
		position: relative;
		top: -30px;
	}
}
h7{
	position: relative;
  top: 105px;
  font-size: 1.5rem;
  color: gray;
  font-weight: 600;
  @media(max-height:850px){
	top: 15px;
}
@media(max-width:450px){
	position: relative;
	top: -25px !important;
}
}